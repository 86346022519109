export const MenuItems = [
  {
    title: "Team",
    url: "/team",
    cName: "nav-links",
  },
  {
    title: "Activities",
    url: "/activities",
    cName: "nav-links",
  },
  {
    title: "Gallery",
    url: "/gallery",
    cName: "nav-links",
  },
  {
    title: "More",
    url: "/more",
    cName: "nav-links",
  },
];
