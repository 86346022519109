import React from "react";
import "./Header.css";

function Header() {
  return (
    <div className="Header">
      <div className="header-container">
        <h1 className="header-title">
          Activities / <span>Webinars</span>
        </h1>
      </div>
    </div>
  );
}

export default Header;
